<template>
    <div class="plate-center">
        <!-- 面包屑 S -->
        <div class="yh-h88 level_align yh-fz14">
            当前位置：
            <el-breadcrumb
                class="breadcrumb"
                separator-class="el-icon-arrow-right"
            >
                <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
                <el-breadcrumb-item>
                    <span class="yh-fc-blue-dan">{{ pageName }}</span>
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <!-- 面包屑 E -->
        <!-- 富文本详情 S-->
        <div class="yh-pd30 yh-bbox" v-html="fwb"></div>
        <!-- 富文本详情 S-->
    </div>
</template>
<script>
export default {
    name: "about",
    data() {
        return {
            pageName: "关于我们", // 当前页面名字
            fwb: "",
        };
    },
    created() {
        this.getInfo();
    },
    methods: {
        //获取关于我们的数据
        getInfo() {
            this.$axios.get(this.$api.aboutUs).then((res) => {
                if (res.code == 1) {
                    this.fwb = this.parseRichImgStyle(res.data.value);
                }
            });
        },
    },
};
</script>



<style scoped lang="scss">
</style>
